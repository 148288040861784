@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;900&display=swap');
.section1{
   height: 100vh;
   background-color: #001220;
   font-family: 'Dongle', sans-serif;

}

.navdiv{
   /* margin-top: 20px; */
   width: 100%;
   height: 70px;
   /* background-color: aqua; */
   display: flex;
   background-color: #6600FF;

}

.logodiv{
   width: 50%;
   display: flex;
   align-items: center;
   /* justify-content: center; */
}

.logodiv img{
   margin-left: 80px;
}


.joinlinks
{
   width: 50%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}



.but1{
   background-color: transparent;
   border: 2px solid #ff2b83;
   height: 60%;
   /* border: none; */
   margin-left: 30px;
   width: 120px;
   border-radius: 5px;
   outline: none;
   cursor: pointer;
   font-size: 28px;
   color: white;
   transition: 0.2s ease;
   font-weight: 400;
   font-family: 'Dongle', sans-serif;
}

.but1:hover{
   background-color: #ff2b83;
}

.but2{
   font-weight: 400;
   font-size: 28px;

   font-family: 'Dongle', sans-serif;
   margin-right: 40px;
   height: 60%;
   border: none;
   background-color: #ff2b83;
   margin-left: 30px;
   width: 120px;
   border-radius: 5px;
   outline: none;
   cursor: pointer;
   color: white;
   /* font-size: 16px; */
}

.spacer{
   /* aspect-ratio: 960/480; */
   width: 100%;
   height: calc(100vh - 70px);
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
   position: relative;
   /* background-attachment: fixed; */

}

.spacer1
{
      /* aspect-ratio: 960/480; */
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: relative;
      /* background-attachment: fixed; */
}

.layer1{
   /* background-image: url('./layered-waves-haikei.svg'); */
   z-index: 5;
   display: flex;
   /* justify-content: center; */
   /* align-items: center; */
   flex-direction: column;
   /* background-color: aqua; */
}

.layer2{
   /* background-image: url('./layered-waves-haikei\ \(4\).svg'); */

}

.titlelanding{
   font-size: 80px;

   font-family: 'Dongle', sans-serif;
   font-weight: 700;

}

.titlelanding1{
   margin-bottom: 100px;

   font-family: 'Dongle', sans-serif;
   font-weight: 700;
   font-size: 80px;


}

.titlelanding2{

   font-family: 'Dongle', sans-serif;
   font-weight: 700;
   font-size: 80px;


}

.doubledown{
   font-size: 40px;
  
}

i.vert-move {
   -webkit-animation: mover 1s infinite  alternate;
   animation: mover 1s infinite  alternate;
   font-size: 40px;
   margin-top: 40px;
   color: #ff2b83;
   /* color: rgb(0, 0, 0); */
}


@keyframes mover {
   0% { transform: translateY(0); }
   100% { transform: translateY(-10px); }
}

.landcol{
   color: #ff2b83;
}

.contentdiv{
   display: flex;
   flex-direction: column;
   align-items: center;
   font-family: 'Dongle', sans-serif;
   /* margin-top: 130px; */
   background-color: #6600FF;
}

.landtit{
   font-family: 'Be Vietnam Pro', sans-serif;
   font-weight: 900;
   font-size: 55px;
   color: white;
}
.tit1{
   margin-top: 150px;
}

.svg1{
   position: absolute;
   height: auto;
   width: 17%;
   margin-left: 20px;
   top: 400px;
   z-index: 10;
   /* transform: rotateY(180deg); */

   /* transform: rotate(30deg) */
}

.svg2{
   position: absolute;
   height: auto;
   width: 14%;
   /* transform: rotate(-40deg); */
   transform: rotateY(180deg);
   top: 370px;
   right: 0%;
   z-index: 10;
}

.slogan{
   color: white;
   font-size: 25px;
   margin-top: 20px;
}

.contentdiv2{
   display: flex;
   width: 100%;
   align-items: center;
   /* margin-top: 150px; */
   font-family: 'Dongle', sans-serif;
   flex-direction: column;

}

.sec2tit{
   margin-top: 100px;
   font-size: 26px;
   font-family: 'Be Vietnam Pro', sans-serif;
   font-weight: 900;
}

.sec2conwrap{
   margin-top: 10px;
   width: 70%;
   font-family: 'Dongle', sans-serif;
   font-size: 36px;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items:center;
   height: 1100px;
}

.how1,.how2,.how3,.how4{
   display: flex;
   justify-content: space-evenly;
   width: 100%;
   align-items: center;
}

.summa{
   width: 40%;
}

.howimg{
   height: auto;
   width: 310px;
   /* border-radius: 50%;
   background-color: violet; */
}

.layer2{
   background-image: url('./wave-haikei\ \(1\).svg');
   /* margin-top: 1200px; */
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   background-color: #6600FF;
   height: 1000px;

}

.titsec3{
   margin-top: 250px;
   color: white;
}

.conwroap3{
   color: white;
}

.sec3col
{
   color: #ff2b83;
}

.sec4conwrap{
   /* margin-top: 30px; */
   width: 70%;
   font-family: 'Dongle', sans-serif;
   font-size: 36px;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   /* align-items:center; */
   /* height: 1300px; */
}

.layer3{
   margin-top: 140px;
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   margin-bottom: 60px;
}

.how5{
   display: flex;
   justify-content: space-evenly;
   width: 100%;
   align-items: center;
   flex-direction: column;
}

.layer4{

   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   margin-bottom: 60px;
   flex-direction: column;
   align-items: center;
   color: black;
}

.sec5col
{
   color: #6600FF;
}

.navland{
   height: 100px;
   width: 100%;
   background-color: #6600FF;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   
}

@media only screen and (max-width:1688px)
{
   .landtit{
      font-size: 45px;
   }
}