.cardcontainer{
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cardcontainer1{
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 90%;
}

.infocontainer{
    display: flex;
    height: 80px;
    width: 100%;
    z-index: 1;
}

.circle{
    position: relative;
    left: 25px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #482ff7;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid white;
    z-index: 2;
}

.circle img{
    height: 100%;
    width: 100%;
}

.rectangle{
    position: relative;
    right: 25px;
    width: 95%;
    display: flex;
    height: 80px;
    /* background-color: tomato; */
    /* background-color: white; */
    background-color: #00DBDE;
background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);

    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


.cardtextcontainer{
    margin-left: 80px;
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.propertyname{
    font-size: 24px;
    font-weight: 500;
}

.grey{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 20%;
    background-color: rgb(100, 100, 100);
    height: 100%;
}


.bedroom{
    width: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.stairs
{
    height: 20px;
    width: 20px;
}

.props{
    display: flex;
    width: 200px;
    justify-content: space-evenly;
    height: 20px;
}

.likecomment{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 40px;
}

.likebtn{
    border-radius: 5px;
    width: 120px;
    height: 80%;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    bottom: 4px;
}

.commentbtn{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 120px;
    border-radius: 5px;
    height: 80%;
    background-color: black;
    position: relative;
    bottom: 4px;
    right: 20px;
}

.commentbtn span{
    font-size: 13px;
}


.grey1{
    background-color: #3116e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 15%;
    height: 100%;
    font-weight: 600;
}

.grey2{
    background-color: rgb(251, 92, 92);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 15%;
    height: 100%;
    color: black;
    font-weight: 600;

}