.viewreqcard{
    font-family: 'Poppins', sans-serif;
    margin-top: 80px ;
    margin-left: 300px;
    background-color: rgb(255, 255, 255);
}

.viewreqtitle{
    font-size: 40px;
    font-weight: 400;
    margin-left: 80px;
}

.allcontainer
{
    /* margin-top: 100px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewreqcontainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin-top: 10px; */

   align-items: center;
}

.sortdiv
{

    margin-top: 20px;
    margin-right: 200px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 40px;
    width: 100px;
    background-color: #3119ce;
    border-radius: 10px;
}

.sendrecieve{
    width: 90%;
    background-color: black;
    color: white;
    display: flex;
    /* justify-content: space-evenly; */
    margin-top: 10px;
    height: 50px;
    align-items: center;
    font-size: 20px;

}
.send{
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
}

.recieve{
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;
    align-items: center;
}

.gridsendres{
    width: 95%;
    height: 65vh;
    display: flex;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    border-radius:  10px;
    justify-content: center;
    overflow-y: scroll;
    /* background-color: wheat; */
}

.gridsendres::-webkit-scrollbar{
    width: 10px;
    cursor: pointer;

}
.gridsendres::-webkit-scrollbar-thumb{
    border-radius: 120vw;
    background-color: rgb(92, 92, 92);
}

.gridsendres::-webkit-scrollbar-track{
    background-color: rgb(255, 255, 255);
}

.gridsend{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background-color: violet; */
    margin-top: 30px;
}   

.gridres{
    display: flex;
    margin-top: 30px;


    flex-direction: column;
    align-items: center;
    width: 80%;
    /* background-color: turquoise; */
    height: 100%;
}

.none{
    display: none;
}

.bgwhite
{
    background-color: rgb(97, 97, 97);
}

.declinemodel{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: fixed;
    background-color: rgb(0, 0, 0,0.6);
    height: 100%;
    width: 100%;
    z-index: 3;
    align-items: center;
    display: flex;
    justify-content: center;
}

.dmodel{
    height: 40vh;
    width: 30vw;
    background-color: rgb(243, 243, 243);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.dmodel span{
    font-size: 27px;
    font-weight: 600;
}


.reasonfordecline{
    height: 50px;
    width: 70%;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 20px ;
}

.dmodel button{
    padding: 15px 30px;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #3821d3;
    color: white;
    cursor: pointer;
}

.declinemodelclose{
    display: none;
}

.closebtn{
    position: absolute;
    top: 32%;
    left: 63%;
    font-size: 20px;
    cursor: pointer;
}

