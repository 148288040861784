@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@200;400&display=swap');


.submitreqpage{
    font-family: 'Poppins', sans-serif;
    margin-left: 300px;
    /* background-color: chartreuse; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    
}

.title{
    padding-top: 50px;

}

.choosecontainer{
    margin-top: 80px;
    height: 400px;
    width: 1000px;
    display: flex;
    justify-content: space-around;
}

.forsale,.forrent{
    width: 400px;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: transform 0.1s ease-in;
}



.imgs{
    height: auto;
    width: 300px;
}

.forsaleimg{
    height: auto;
    width: 220px;
}

.forsaletext,.forrenttext{
    font-size: 22px;
    text-decoration: none;
    color: black;
}



.hover:hover{
    transform: scale(1.03);
}