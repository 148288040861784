.landingdash{
    margin-top: 80px;
    margin-left: 300px;
    margin-bottom: 100px;
}

.hometitle{
    font-size: 30px;
    margin-left: 40px;
    font-weight: 600;

}

.landingdashcontainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.today,.Yesterday,.Lastthreedays{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homesubtitle span{
    font-size: 20px;
    font-weight: 600;
}

.declinemodelinfo{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: fixed;
    background-color: rgb(0, 0, 0,0.6);
    height: 100%;
    width: 100%;
    z-index: 3;
    align-items: center;
    display: flex;
    justify-content: center;
}

.dmodelinfo{
    height: 50vh;
    width: 40vw;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
}




.reasonfordeclineinfo{
    height: 50px;
    width: 70%;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 18px;
}

.dmodelinfo button{
    padding: 15px 30px;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #3821d3;
    color: white;
    cursor: pointer;
}

.declinemodelcloseinfo{
    display: none;
}

.closebtninfo{
    position: absolute;
    top: 28%;
    left: 67%;
    font-size: 20px;
    cursor: pointer;
}

.subinfos{
    font-size: 18px;
    margin: 20px;
}

.subinfos1{
    color: rgb(53, 53, 179);
}

.moreinfo1{
    width: 40%;
    height: 80%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.moreinfo2{
    width: 40%;
    height: 80%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.moreinfotitle{
    
}

.moreinfocontainer{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.asdf{
    position: absolute;
    top: 30%;
    left: 32%;
    font-weight: 600;
    font-size: 20px;
}



.colorline{
    height: 10px;
    width: 100%;
    background-color: rgb(39, 39, 143);
    border-radius: 10px;
}

