.feedback{
    margin-left: 300px;
    margin-top: 70px;
    width: calc(100vw - 300px);
    display: flex;
    align-items: center;
    flex-direction:column;
}

.feedback h1{
    margin-top: 40px;
}

.feedbackcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    width: 50%;
    height: 500px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.feedbackcontainer label{
    margin-right: 50%;
    margin-bottom: 20px;
    color: #700dff;
    font-size: 18px;
    font-weight: 600;
}

.feedbackcontainer textarea{
    height: 50%;
    width: 60%;
}

.feedbackcontainer button{
    margin-top: 40px;
    color: white;
    background-color: #f80066;
    border: none;
    height: 40px;
    width: 120px;
    border-radius: 10px;
    font-family: sans-serif;
    font-size: 16px;
    cursor: pointer;
}